<template>
  <div class="modal fade" id="addManualWinnerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Manual Winner</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="form-group">
            <label class="m-0">1st Place</label>
            <select v-model="selected_group_member1" class="form-control">
              <option value="">Choose Group Member</option>
              <option :value="group_member.id" v-for="(group_member, index) in arr_group_member1" :key="index">{{ group_member.team_name }}</option>
            </select>
          </div>

					<div class="form-group">
						<label class="m-0">2nd Place</label>
						<select v-model="selected_group_member2" class="form-control">
							<option value="">Choose Group Member</option>
							<option :value="group_member.id" v-for="(group_member, index) in arr_group_member2" :key="index">{{ group_member.team_name }}</option>
						</select>
					</div>

					<div class="form-group">
						<label class="m-0">3rd Place</label>
						<select v-model="selected_group_member3" class="form-control">
							<option value="">Choose Group Member</option>
							<option :value="group_member.id" v-for="(group_member, index) in arr_group_member3" :key="index">{{ group_member.team_name }}</option>
						</select>
					</div>

					<div class="form-group">
						<label class="m-0">Juara Harapan 1 / Runner up 1</label>
						<select v-model="selected_group_member_runner_up1" class="form-control">
							<option value="">Choose Group Member</option>
							<option :value="group_member.id" v-for="(group_member, index) in arr_group_member_runner_up1" :key="index">{{ group_member.team_name }}</option>
						</select>
					</div>

					<div class="form-group">
						<label class="m-0">Juara Harapan 2 / Runner up 2</label>
						<select v-model="selected_group_member_runner_up2" class="form-control">
							<option value="">Choose Group Member</option>
							<option :value="group_member.id" v-for="(group_member, index) in arr_group_member_runner_up2" :key="index">{{ group_member.team_name }}</option>
						</select>
					</div>

					<div class="form-group">
						<label class="m-0">Juara Harapan 3 / Runner up 3</label>
						<select v-model="selected_group_member_runner_up3" class="form-control">
							<option value="">Choose Group Member</option>
							<option :value="group_member.id" v-for="(group_member, index) in arr_group_member_runner_up3" :key="index">{{ group_member.team_name }}</option>
						</select>
					</div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="submit">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'

import NoImage from '@/assets/no_image.png'

export default {
  props: ['match_data', 'event_data', ],
  components: {
  },
  data() {
    return {
      base: null,
      arr_factor: [false, ],
      selected_group_member1: '',
			selected_group_member2: '',
			selected_group_member3: '',
			selected_group_member_runner_up1: '',
			selected_group_member_runner_up2: '',
			selected_group_member_runner_up3: '',
      selected_registration_event_player: '',
			base_arr_group_member1: [],
      // arr_group_member1: [],
			// arr_group_member2: [],
			// arr_group_member3: [],
      arr_registration_event_player: [],
    }
  },
	computed: {
		arr_group_member1(){
			var arr = JSON.parse(JSON.stringify(this.base_arr_group_member1))
			var arr_temp = []
			for(let group_member of arr){
				if(
					group_member.id != this.selected_group_member2 && group_member.id != this.selected_group_member3 &&
					group_member.id != this.selected_group_member_runner_up1 && group_member.id != this.selected_group_member_runner_up2 &&
					group_member.id != this.selected_group_member_runner_up3
				)
					arr_temp.push(group_member)
			}
			return arr_temp
		},
		arr_group_member2(){
			var arr = JSON.parse(JSON.stringify(this.base_arr_group_member1))
			var arr_temp = []
			for(let group_member of arr){
				if(
					group_member.id != this.selected_group_member1 && group_member.id != this.selected_group_member3 &&
					group_member.id != this.selected_group_member_runner_up1 && group_member.id != this.selected_group_member_runner_up2 &&
					group_member.id != this.selected_group_member_runner_up3
				)
					arr_temp.push(group_member)
			}
			return arr_temp
		},
		arr_group_member3(){
			var arr = JSON.parse(JSON.stringify(this.base_arr_group_member1))
			var arr_temp = []
			for(let group_member of arr){
				if(
					group_member.id != this.selected_group_member2 && group_member.id != this.selected_group_member1 &&
					group_member.id != this.selected_group_member_runner_up1 && group_member.id != this.selected_group_member_runner_up2 &&
					group_member.id != this.selected_group_member_runner_up3
				)
					arr_temp.push(group_member)
			}
			return arr_temp
		},
		arr_group_member_runner_up1(){
			var arr = JSON.parse(JSON.stringify(this.base_arr_group_member1))
			var arr_temp = []
			for(let group_member of arr){
				if(
					group_member.id != this.selected_group_member2 && group_member.id != this.selected_group_member1 &&
					group_member.id != this.selected_group_member3 && group_member.id != this.selected_group_member_runner_up2 &&
					group_member.id != this.selected_group_member_runner_up3
				)
					arr_temp.push(group_member)
			}
			return arr_temp
		},
		arr_group_member_runner_up2(){
			var arr = JSON.parse(JSON.stringify(this.base_arr_group_member1))
			var arr_temp = []
			for(let group_member of arr){
				if(
					group_member.id != this.selected_group_member2 && group_member.id != this.selected_group_member1 &&
					group_member.id != this.selected_group_member3 && group_member.id != this.selected_group_member_runner_up1 &&
					group_member.id != this.selected_group_member_runner_up3
				)
					arr_temp.push(group_member)
			}
			return arr_temp
		},
		arr_group_member_runner_up3(){
			var arr = JSON.parse(JSON.stringify(this.base_arr_group_member1))
			var arr_temp = []
			for(let group_member of arr){
				if(
					group_member.id != this.selected_group_member2 && group_member.id != this.selected_group_member1 &&
					group_member.id != this.selected_group_member3 && group_member.id != this.selected_group_member_runner_up2 &&
					group_member.id != this.selected_group_member_runner_up1
				)
					arr_temp.push(group_member)
			}
			return arr_temp
		},
	},
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    minute_time(val){
      this.minute_time = this.base.to_currency_format(val, 120)
    },
    match_data(val){
      var arr_group_member = []
			if(val.cutoff_group != null){
				for(let member of val.cutoff_group.member)
					arr_group_member.push(member.registration_event)
				arr_group_member = arr_group_member.sort((a,b) => {
					return a.team_name.localeCompare(b.team_name)
				})
			}
			this.base_arr_group_member1 = arr_group_member

			if(val.arr_match_event != null){
				this.selected_group_member1 = val.arr_match_event[0] != null ? val.arr_match_event[0].registration_event_id : ''
				this.selected_group_member2 = val.arr_match_event[1] != null ? val.arr_match_event[1].registration_event_id : ''
				this.selected_group_member3 = val.arr_match_event[2] != null ? val.arr_match_event[2].registration_event_id : ''
				this.selected_group_member_runner_up1 = val.arr_match_event[3] != null ? val.arr_match_event[3].registration_event_id : ''
				this.selected_group_member_runner_up2 = val.arr_match_event[4] != null ? val.arr_match_event[4].registration_event_id : ''
				this.selected_group_member_runner_up3 = val.arr_match_event[5] != null ? val.arr_match_event[5].registration_event_id : ''
			}
    },
  },
  async created() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    this.arr_factor = [true,]
  },
  methods: {
		compare( a, b ) {
			if ( a.team_name < b.team_name )
				return -1
			if ( a.team_name > b.team_name )
				return 1
			return 0
		},
    async get_player_data(registration_event) {
      var date = this.date != '' && this.date != null ? momentTZ(this.date, 'DD/MM/YYYY') : ''
      var response = await this.base.request(this.base.url_api + '/event/registration/player/all?registration_event_id=' + registration_event.id)

      if (response != null) {
        if (response.status == "success") {
          for(let player of response.data){
            player.url_image = player.file_name != null ? this.base.host + '/media/user?file_name=' + player.file_name : NoImage
          }

          this.arr_registration_event_player = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async submit(){
      // if(this.selected_group_member1 == '')
      //   this.base.show_error('1st Place is not Selected')
			// else if(this.selected_group_member2 == '')
			// 	this.base.show_error('2nd Place is not Selected')
			// else if(this.selected_group_member3 == '')
			// 	this.base.show_error('3rd Place is not Selected')
			// else if(this.selected_group_member_runner_up1 == '')
			// 	this.base.show_error('Juara Harapan 1 / Runner up 1 is not Selected')
			// else if(this.selected_group_member_runner_up2 == '')
			// 	this.base.show_error('Juara Harapan 2 / Runner up 2 is not Selected')
			// else if(this.selected_group_member_runner_up3 == '')
			// 	this.base.show_error('Juara Harapan 3 / Runner up 3 is not Selected')
      // else{
        window.$('#please_wait_modal').modal('show')
        var data = {
          id: this.match_data.id,
					arr_rank: [
						this.selected_group_member1,
						this.selected_group_member2,
						this.selected_group_member3,
						this.selected_group_member_runner_up1,
						this.selected_group_member_runner_up2,
						this.selected_group_member_runner_up3,
					],
        }

        var response = await this.base.request(this.base.url_api + "/match/best-player", 'post', data)

        setTimeout(() => {
          window.$('#please_wait_modal').modal('hide')
        }, 500)
        if (response != null) {
          if (response.status === "success") {

						// setTimeout(() => {
							window.$('#addManualWinnerModal').modal('hide')
							this.$emit('onMatchFinished', response.data.original.is_finished)
							this.$emit('onForceReload', true)

							this.selected_group_member = ''
							this.selected_registration_event_player = ''
						// }, 1000)
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      // }
    }
  }
}
</script>

<style lang="scss">
.competition-detail-venue-court{
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>
