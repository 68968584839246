<template>
  <div class="modal fade" id="addMatchEventModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ event_data.id != null ? 'Edit' : 'Add' }} Match Event</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

					<div class="d-flex align-items-center h-100 mb-3" v-if="member != null && member.registration_event != null">
						<div class="d-flex align-items-center justify-content-center" style="height: 5rem; width: 5rem;">
							<img :src="member.registration_event.url_image" style="max-height: 100%; width: auto; max-width: 100%;" />
						</div>
						<div class="ml-3">
							<p class="m-0 font-weight-bold">{{ member.registration_event.team_name }}</p>
							<p class="m-0">{{ member.registration_event.user.name }}</p>
						</div>
					</div>

					<div class="form-group" v-else>
						<label class="m-0">Group Member</label>
						<select v-model="selected_group_member" :disabled="event_data.id != null" class="form-control">
							<option value="">Choose Group Member</option>
							<option :value="group_member.id" v-for="(group_member, index) in arr_group_member" :key="index">{{ group_member.team_name }}</option>
						</select>
					</div>

          <div class="form-group" v-show="cutoff_scoring_type == 'score'">
            <label class="m-0">Total Score</label>
            <input type="text" v-model="total_score" class="form-control">
          </div>

					<div class="form-group" v-show="cutoff_scoring_type == 'timer'">
						<label class="m-0">Total Time</label>
						<div class="d-flex align-items-center">
							<input type="text" v-model="time_hour" class="form-control">
							<p class="m-0 mx-1">:</p>
							<input type="text" v-model="time_minute" class="form-control">
							<p class="m-0 mx-1">:</p>
							<input type="text" v-model="time_second" class="form-control">
							<p class="m-0 mx-1">.</p>
							<input type="text" v-model="time_milisecond" class="form-control">
						</div>
					</div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="submit">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'

import NoImage from '@/assets/no_image.png'

export default {
  props: ['match_data', 'event_data', 'member', 'event_category_sport', 'arr_group_member', ],
  components: {
  },
  data() {
    return {
      base: null,
      arr_factor: [false, ],
      selected_group_member: '',
      total_score: '0',
			time_hour: '0',
			time_minute: '0',
			time_second: '0',
			time_milisecond: '0',
			cutoff_scoring_type: 'score',
    }
  },
  watch: {
		member(val){

			this.selected_group_member = val.registration_event.id
		},
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    minute_time(val){
      this.minute_time = this.base.to_currency_format(val, 120)
    },
    total_score(val){
      // var num = this.base.str_to_double(val)
      this.total_score = this.base.to_currency_format(val)
    },
		time_hour(val){
			// var num = this.base.str_to_double(val)
			this.time_hour = this.base.timer_validation(val)
		},
		time_minute(val){
			// var num = this.base.str_to_double(val)
			this.time_minute = this.base.timer_validation(val)
		},
		time_second(val){
			// var num = this.base.str_to_double(val)
			this.time_second = this.base.timer_validation(val)
		},
		time_milisecond(val){
			// var num = this.base.str_to_double(val)
			this.time_milisecond = this.base.timer_validation(val, 3)
		},
		match_data(val){
			if(val.tournament != null)
				this.cutoff_scoring_type = val.tournament.event_category_sport_category.event_category_sport.cutoff_scoring_type
			else if(val.cutoff_group != null)
				this.cutoff_scoring_type = val.cutoff_group.event_category_sport_category.event_category_sport.cutoff_scoring_type
		},
    event_category_sport(val){
			if(val.id != null){
				this.cutoff_scoring_type = val.cutoff_scoring_type
			}
    },
    event_data(val){
			if(val.id != null){
				this.selected_group_member = val.registration_event != null ? val.registration_event.id : ''
				if(this.cutoff_scoring_type == 'score')
					this.total_score = val.total_score != null ? val.total_score.toLocaleString(this.base.locale_string) : ''
				else if(this.cutoff_scoring_type == 'timer'){
					var total_timer = this.base.init_duration(val.total_score)

					this.time_hour = total_timer.hours().toLocaleString(this.base.locale_string)
					this.time_minute = total_timer.minutes().toLocaleString(this.base.locale_string)
					this.time_second = total_timer.seconds().toLocaleString(this.base.locale_string)
					this.time_milisecond = (total_timer.milliseconds() / 1000).toLocaleString(this.base.locale_string).substring(2)
				}
			}
			else{
				this.total_score = '0'
				this.time_hour = '0'
				this.time_minute = '0'
				this.time_second = '0'
				this.time_milisecond = '0'
			}
    },
  },
  async created() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    this.arr_factor = [true,]
  },
  methods: {
    async submit(){
      if(this.total_score == '0' && this.cutoff_scoring_type == 'score')
				this.base.show_error('Total Score is Empty')
      else if((this.time_hour == '0' && this.time_minute == '0' && this.time_second == '0' && this.time_milisecond == '0') && this.cutoff_scoring_type == 'timer')
        this.base.show_error('Total Time is Empty')
      else{
        // window.$('#please_wait_modal').modal('show')
				if(this.cutoff_scoring_type == 'timer'){
					var total_timer = momentTZ.duration({
						hours: this.time_hour,
						minutes: this.time_minute,
						seconds: this.time_second,
						milliseconds: this.time_milisecond.length == 1 ? this.time_milisecond + '00' : this.time_milisecond.length == 2 ? this.time_milisecond + '0' :this.time_milisecond,
					})
				}

        var data = {
          match_id: this.match_data.id,
          total_score: this.cutoff_scoring_type == 'timer' ? total_timer.as('seconds') : this.base.str_to_double(this.total_score),
        }

				data.registration_event_id = this.selected_group_member

        if(this.event_data.id != null)
          data.id = this.event_data.id


        var response = await this.base.request(this.base.url_api + "/match/event" + (this.event_data.id != null ? '/edit' : ''), 'post', data)

        setTimeout(() => {
          window.$('#please_wait_modal').modal('hide')
        }, 500)
        if (response != null) {
          if (response.status === "success") {
						this.total_score = '0'
						this.minute_time = '0'
						this.time_hour = '0'
						this.time_minute = '0'
						this.time_second = '0'
						this.time_milisecond = '0'
						this.selected_group_member = ''
						this.selected_registration_event_player = ''

            window.$('#addMatchEventModal').modal('hide')
            this.$emit('onForceReload', false)
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    }
  }
}
</script>

<style lang="scss">
.competition-detail-venue-court{
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>
