<template>
  <div class="d-flex justify-content-between">
    <div>
      <p class="p-3 year-text m-0">{{ competition_data.id != null ? competition_data.event.name : '-' }}</p>
    </div>
    <div class="d-flex overflow-auto">
      <div v-for="(data, index) in arr" :key="index" class="competition-tab-col" :class="data.is_active ? 'active' : ''" @click="onClicked(index)">
        <p class="m-0 p-3 no-wrap">{{ data.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

export default {
  components: {},
  props: ['competition_data',],
  data() {
    return {
      base: null,
      arr: [
      ],
    }
  },
  watch: {
    competition_data(val){
      var arr = []
      arr.push(
        {
          id: 'information',
          name: 'Information',
          url: '/competition/information',
        },
        {
          id: 'venue',
          name: 'Venue & Field',
          url: '/competition/venue',
        },
        {
          id: 'team',
          name: 'School / Club',
          url: '/competition/school',
        },
      )

			if(this.competition_data.scoring_type.data == 'tournament' || this.competition_data.scoring_type.data == 'league')
				arr.push(
					{
						id: 'group',
						name: 'Group',
						url: '/competition/group',
					},
				)

			if(this.competition_data.scoring_type.data == 'tournament' || this.competition_data.scoring_type.data == 'league')
				arr.push(
					{
						id: 'schedule',
						name: 'Schedule',
						url: '/competition/match',
					},
				)
			else if(this.competition_data.scoring_type.data == 'cutoff' || this.competition_data.scoring_type.data == 'cutoff_tournament')
				arr.push(
					{
						id: 'result',
						name: 'Result',
						url: '/competition/cutoff',
					},
				)
			else if(this.competition_data.scoring_type.data == 'manual')
				arr.push(
					{
						id: 'result',
						name: 'Result',
						url: '/competition/cutoff',
					},
				)

      if(this.competition_data.scoring_type.data == 'tournament' || this.competition_data.scoring_type.data == 'cutoff_tournament' || this.competition_data.scoring_type.data == 'tournament_no_group')
        arr.push(
          {
            id: 'tournament',
            name: 'Tournament',
            url: '/competition/tournament',
          },
        )

			if(this.competition_data.scoring_type.data == 'league' || this.competition_data.scoring_type.data == 'tournament')
				arr.push(
					{
						id: 'ladder',
						name: 'Ladder',
						url: '/competition/ladder',
					},
				)

			if(this.competition_data.scoring_type.data != 'manual')
				arr.push(
					{
						id: 'top_scorer',
						name: 'Top Scorer',
						url: '/competition/top-scorer',
					},
					{
						id: 'best_player',
						name: 'Best Player',
						url: '/competition/best-player',
					},
				)

      for(let data of arr){
        data.name = this.$t(data.id)
        data.is_active = data.url == window.location.pathname
      }

      this.arr = arr
    }
  },
  created() {
    this.base = new Base()





  },
  methods: {
    onClicked(index){
      var data = this.arr[index]
      if(!data.is_active)
        window.location.href = data.url + "?id=" + this.$route.query.id
    }
  }
}
</script>

<style lang="scss">
.tab-container{
  overflow-x: auto;
}
.year-text{
  color: $primary;
  font-family: 'montserrat-medium';
}
.competition-tab-col{
  background-color: 'white';
  cursor: pointer;
}
.competition-tab-col p{
  color: $gray20;
  font-family: 'montserrat-regular';
  // font-size: 1.2rem;
}
.competition-tab-col.active{
  // background-color: $primary;
}
.competition-tab-col.active p{
  background-color: $primary;
  color: $warning;
  font-family: 'montserrat-regular';
  // font-size: 1.2rem;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

.no-wrap{
  white-space: nowrap;
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>
