<template>
  <div class="modal fade" id="addBestPlayerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Best Player</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="form-group">
            <label class="m-0">Group Member</label>
            <select v-model="selected_group_member" class="form-control">
              <option value="">Choose Group Member</option>
              <option :value="group_member.id" v-for="(group_member, index) in arr_group_member" :key="index">{{ group_member.team_name }}</option>
            </select>
          </div>


          <div class="form-group" v-if="(match_data.cutoff_group == null && match_data.tournament == null) || (match_data.tournament != null && match_data.tournament.event_category_sport.scoring_type.data != 'cutoff_tournament')">
            <label class="m-0">Player</label>
            <select v-model="selected_registration_event_player" class="form-control">
              <option value="">Choose Player</option>
              <option :value="registration_event_player.id" v-for="(registration_event_player, index) in arr_registration_event_player" :key="index">{{ registration_event_player.name }}</option>
            </select>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="submit">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'

import NoImage from '@/assets/no_image.png'

export default {
  props: ['match_data', 'event_data', ],
  components: {
  },
  data() {
    return {
      base: null,
      arr_factor: [false, ],
      selected_group_member: '',
      selected_registration_event_player: '',
      arr_group_member: [],
      arr_registration_event_player: [],
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    minute_time(val){
      this.minute_time = this.base.to_currency_format(val, 120)
    },
    selected_group_member(val){
      var selected_group_member = {}
      for(let group_member of this.arr_group_member){
        if(val == group_member.id){
          selected_group_member = group_member
          break
        }
      }
      this.get_player_data(selected_group_member.registration_event != null ? selected_group_member.registration_event : selected_group_member)
    },
    match_data(val){
      var arr_group_member = []
      if(val.member1 != null && val.member2 != null){
        val.member1.team_name = val.member1.registration_event.team_name
        val.member2.team_name = val.member2.registration_event.team_name

        arr_group_member.push(val.member1)
        arr_group_member.push(val.member2)
      }
      else if(val.tournament != null){
        arr_group_member.push(val.tournament.registration_event1)
        arr_group_member.push(val.tournament.registration_event2)
      }
			else if(val.cutoff_group != null){
				for(let member of val.cutoff_group.member)
					arr_group_member.push(member.registration_event)
			}
			arr_group_member = arr_group_member.sort((a,b) => {
				return a.team_name.localeCompare(b.team_name)
			})
      this.arr_group_member = arr_group_member
    },
  },
  async created() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    this.arr_factor = [true,]
  },
  methods: {
    async get_player_data(registration_event) {
      var date = this.date != '' && this.date != null ? momentTZ(this.date, 'DD/MM/YYYY') : ''
      var response = await this.base.request(this.base.url_api + '/event/registration/player/all?registration_event_id=' + registration_event.id)

      if (response != null) {
        if (response.status == "success") {
          for(let player of response.data){
            player.url_image = player.file_name != null ? this.base.host + '/media/user?file_name=' + player.file_name : NoImage
          }
					response.data = response.data.sort((a,b) => {
						return a.name.localeCompare(b.name)
					})

          this.arr_registration_event_player = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async submit(){
      if(this.selected_type != 'match_finished' && this.selected_group_member == '')
        this.base.show_error('Group Member is not Selected')
      else if(this.selected_type != 'match_finished' && this.selected_registration_event_player == '' &&
				(
					(this.match_data.cutoff_group == null && this.match_data.tournament == null) ||
					(this.match_data.tournament != null && this.match_data.tournament.event_category_sport.scoring_type.data != 'cutoff_tournament')
				)
			)
        this.base.show_error('Player is not Selected')
      else{
        window.$('#please_wait_modal').modal('show')
        var data = {
          id: this.match_data.id,
        }

				if(this.match_data.cutoff_group != null || this.match_data.tournament != null)
					data.best_group_member_id = this.selected_group_member
				else
					data.best_player_id = this.selected_registration_event_player

        var response = await this.base.request(this.base.url_api + "/match/best-player", 'post', data)

        setTimeout(() => {
          window.$('#please_wait_modal').modal('hide')
        }, 500)
        if (response != null) {
          if (response.status === "success") {
            window.$('#addBestPlayerModal').modal('hide')
            this.$emit('onMatchFinished', response.data.original.is_finished)
						this.$emit('onForceReload', false)

						this.selected_group_member = ''
						this.selected_registration_event_player = ''
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    }
  }
}
</script>

<style lang="scss">
.competition-detail-venue-court{
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>
